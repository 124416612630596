import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Badge, Popover, Tooltip, Spin, Button, Popconfirm, } from 'antd';
import { LoadingOutlined, ShareAltOutlined } from '@ant-design/icons';
import { listProjectSharing, unshareProject } from 'api/project-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const SharedBadge = ({ project, type = 'standalone', onRefetch }) => {
    const [sharedWith, setSharedWith] = useState(null);
    const { session } = useSession();
    const handlePopoverOpenChange = async (visible) => {
        if (visible === false) {
            return;
        }
        setSharedWith(await listProjectSharing(session.connection, project.id, session.login.user.email));
    };
    const handleUnshare = async () => {
        await unshareProject(session.connection, project);
        void onRefetch();
    };
    return (_jsx(Popover, { placement: "rightTop", title: (_jsxs(_Fragment, { children: [_jsx(ShareAltOutlined, {}), "\u00A0 \u00A0", txt('sharedWith')] })), onOpenChange: handlePopoverOpenChange, content: sharedWith === null
            ? _jsx(Spin, { indicator: _jsx(LoadingOutlined, {}) })
            : (_jsxs("div", { children: [sharedWith.map((email) => (_jsx("div", { children: email }, email))), project.creatorId !== null && Number(session.login.user.id) === project.creatorId ? (_jsx(Popconfirm, { title: txt('unshareProject'), description: txt('unshareAlert'), onConfirm: handleUnshare, children: _jsx(Button, { size: "small", type: "primary", danger: true, className: styles.button, children: txt('unshare') }) })) : null] })), trigger: "click", children: _jsx(Tooltip, { title: txt('show'), placement: "top", children: _jsx(Badge, { className: styles[`badge${type}`], title: " ", count: (project.shareCount - 1), size: type === 'standalone' ? 'default' : 'small', offset: type === 'standalone' ? undefined : [-8, -2] }) }) }));
};
export default SharedBadge;
