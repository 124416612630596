import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Flex, Select, Popconfirm, Button, Divider, } from 'antd';
import { txt } from 'libs/i18n';
import { normalizeString } from 'libs/normalize-string';
import styles from './style.module.less';
const filterOption = (inputValue, selectOption) => {
    if (selectOption === undefined)
        return false;
    const normalizedOption = normalizeString(selectOption.label.toLocaleLowerCase());
    if (inputValue.includes(' ')) {
        return normalizedOption.includes(normalizeString(inputValue));
    }
    return normalizedOption.split(' ').some((option) => option.startsWith(normalizeString(inputValue)));
};
const TransferForm = ({ options, onHandleTransfer, tooltipDescription, title, validatorText, }) => {
    const [form] = Form.useForm();
    const newId = Form.useWatch('newId', form);
    const handleTransfer = async () => {
        await form.validateFields();
        onHandleTransfer(newId);
        form.resetFields();
    };
    return (_jsxs(_Fragment, { children: [_jsx("p", { className: styles.dangerText, children: title }), _jsx(Divider, { className: styles.divider }), _jsx(Form, { form: form, children: _jsxs(Flex, { gap: "middle", align: "baseline", children: [_jsx(Form.Item, { name: "newId", className: styles.select, rules: [
                                {
                                    required: true,
                                    message: validatorText,
                                },
                            ], children: _jsx(Select, { showSearch: true, options: options, filterOption: filterOption }) }), _jsx("div", { children: _jsx(Popconfirm, { title: `${title}?`, description: tooltipDescription, onConfirm: handleTransfer, children: _jsx(Button, { type: "primary", size: "small", danger: true, children: txt('transfer') }) }) })] }) })] }));
};
export default TransferForm;
