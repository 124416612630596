import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { txt } from 'libs/i18n';
import { useState } from 'react';
import styles from './style.module.less';
const DownloadButton = ({ onHandleExport, title }) => {
    const [isExporting, setIsExporting] = useState(false);
    const handleExport = async () => {
        setIsExporting(true);
        await onHandleExport();
        setIsExporting(false);
    };
    return (_jsxs(Button, { onClick: handleExport, type: "primary", size: "small", className: styles.button, disabled: isExporting, children: [isExporting ? (_jsx(Spin, { size: "small", className: styles.spinnerIcon, indicator: _jsx(LoadingOutlined, {}) })) : (_jsx(DownloadOutlined, {})), _jsx("span", { className: styles.buttonText, children: isExporting ? `${txt('exportProgress')}...` : title })] }));
};
export default DownloadButton;
