import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Loading from 'components/Loading';
import { useState } from 'react';
import TranscribeModal from 'screens/DashboardScreen/components/TranscribeModal';
import { txt } from 'libs/i18n';
import ErrorAlert from './ErrorAlert';
import ProjectAlert from '../ProjectAlert';
import './style.less';
const MessagePanel = ({ mediaProcessingStatus, projectErrorStatus, projectQueuePosition, documentLoadingFinished, project, }) => {
    const [transcribeModalVisible, setTranscribeModalVisible] = useState(false);
    if (projectErrorStatus !== 'no-error') {
        return (_jsxs(_Fragment, { children: [_jsx(ErrorAlert, { projectError: projectErrorStatus, onClick: () => setTranscribeModalVisible(true) }), _jsx(TranscribeModal, { visible: transcribeModalVisible, onClose: () => setTranscribeModalVisible(false), project: project })] }));
    }
    if (projectQueuePosition !== null) {
        return (_jsx(ProjectAlert, { type: "info", title: txt('projectInQueue'), description: `${txt('orderInQueue')}: ${projectQueuePosition}` }));
    }
    if (mediaProcessingStatus === 'words-arriving') {
        return null;
    }
    if (mediaProcessingStatus === 'transcribing') {
        return _jsx(Loading, { message: txt('noWordsYet') });
    }
    if (!documentLoadingFinished) {
        return _jsx(Loading, { message: txt('loadingProject') });
    }
    if (mediaProcessingStatus === 'preparing-media') {
        return _jsx(Loading, { message: txt('converting') });
    }
    if (mediaProcessingStatus === 'loading-project') {
        return _jsx(Loading, { message: txt('loadingProject') });
    }
    if (mediaProcessingStatus === 'nofaststart-conversion') {
        return _jsx(Loading, { message: txt('waitingForConversion') });
    }
    return null;
};
export default MessagePanel;
