import { formatTime } from 'libs/format-number';
import shouldJoinNext from './nbsp-words';
export const TIME_ANCHOR_SYMBOL = '\u23F1'; // ⏱️
export const CAPTION_END_SYMBOL = '\u25C6'; // ◆
// NOTE: Single char special symbols must be included in this regex, it's used in findClosestLetter.
export const SPECIAL_SYMBOL_REGEX = new RegExp(`${TIME_ANCHOR_SYMBOL}|${CAPTION_END_SYMBOL}`);
export const TIME_STAMP_MARKER_REGEX = '\\{\\d{2}:\\d{2}:\\d{2}\\}'; // {HH:mm:ss}
export const NBSP_DOT_SYMBOL = '\u2981'; // ⦁
export const NONSPACE_REGEX = new RegExp(`[^\u0020\u00a0${NBSP_DOT_SYMBOL}\n]`);
export const SPACE_REGEX_G = new RegExp(`[\u0020\u00a0${NBSP_DOT_SYMBOL}\n]`, 'g');
export const ALL_WHITESPACE_REGEX = new RegExp(`^[\u0020\u00a0${NBSP_DOT_SYMBOL}\n]*$`);
export const CLEANUP_REGEX = new RegExp(`[\n${TIME_ANCHOR_SYMBOL}${CAPTION_END_SYMBOL}]|${TIME_STAMP_MARKER_REGEX}`, 'g');
export const LETTER_REGEX = new RegExp(`[^-\\}{)("\u0020\u00a0\n${NBSP_DOT_SYMBOL}\u23F1\u25C6]`);
export const INTERPUNCTIONS_REGEX = /[.,;:!\\?]/;
export const INTERPUNCTIONS_REGEX_G = /[.,;:!\\?]/g;
export const WORD_BOUNDARY_CHAR_REGEX = new RegExp(`[\u0020\u00a0\n\u25C6${NBSP_DOT_SYMBOL}]`);
export const QUILL_CURSOR = '\ufeff';
export const cleanUpText = (text) => text.replace(CLEANUP_REGEX, '');
export const formatTimeStampMarker = (time) => `{${formatTime(time, 'HH:mm:ss')}}`;
export function findFirstNonSpace(text, offset = 0) {
    const index = text.substr(offset).search(NONSPACE_REGEX);
    return index > -1 ? offset + index : -1;
}
export function getAllMatchIndices(text, regex) {
    return Array.from(text.matchAll(regex)).map((match) => {
        if (match.index === undefined) {
            throw new Error('invalid match');
        }
        return match.index;
    });
}
export function trimStart(string) {
    let startIndex = 0;
    while (!NONSPACE_REGEX.test(string[startIndex])) {
        startIndex += 1;
    }
    return string.substr(startIndex);
}
export function capitalizeWord(text, offset = 0) {
    const charIndex = findFirstNonSpace(text, offset);
    if (charIndex < 0)
        return { word: text, changed: false };
    let upper = text[charIndex].toUpperCase();
    if (offset > 0 && charIndex === offset) {
        upper = `${upper}`;
    }
    const word = (text.substr(0, charIndex)
        + upper
        + text.substr(charIndex + 1));
    return {
        word,
        changed: true,
    };
}
export function addNonbreakingSpaces(text, language = null) {
    let fixedText = '';
    const wordStarts = Array.from(text.matchAll(SPACE_REGEX_G)).map((match) => {
        if (match.index === undefined) {
            throw new Error('invalid match');
        }
        return match.index + 1;
    });
    wordStarts.unshift(0);
    for (let i = 1; i < wordStarts.length; i += 1) {
        const from = wordStarts[i - 1];
        const to = wordStarts[i];
        const word = text.substring(from, to - 1);
        const originalSpace = text.substring(to - 1, to);
        fixedText += word;
        if (shouldJoinNext(word, language) && originalSpace !== '\n') {
            fixedText += '\u00a0'; // nbsp
        }
        else {
            fixedText += originalSpace;
        }
    }
    fixedText += text.substring(fixedText.length); // add text after the last space
    return fixedText;
}
