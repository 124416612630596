import Quill from 'quill';
import { inlineblotOrder } from './inline-blot-order';
const TIME_STAMP_MARKER = 'timeStampMarker';
const Inline = Quill.import('blots/inline');
class TimeStampMarkerBlot extends Inline {
    static create(value) {
        const node = super.create(value);
        if (inlineblotOrder.includes(TIME_STAMP_MARKER)) {
            node.className = TIME_STAMP_MARKER;
            node.setAttribute('contenteditable', false);
        }
        else {
            // NOTE: if the format name is missing in blot order, it causes bugs in quill.
            throw new Error('Blot is not listed in inline blot order');
        }
        return node;
    }
    static formats(domNode) {
        return domNode.classList.contains(TimeStampMarkerBlot.blotName);
    }
    format(name, value) {
        if (name === TimeStampMarkerBlot.blotName) {
            if (value) {
                this.domNode.classList.add(TimeStampMarkerBlot.blotName);
                this.domNode.setAttribute('contenteditable', false);
            }
            else {
                this.domNode.classList.remove(TimeStampMarkerBlot.blotName);
                this.domNode.setAttribute('contenteditable', true);
            }
        }
        else {
            super.format(name, value);
        }
    }
    formats() {
        const formats = super.formats();
        formats.timeStampMarker = TimeStampMarkerBlot.formats(this.domNode);
        return formats;
    }
    attach() {
        if (this.parent != null) {
            this.scroll = this.parent.scroll;
        }
    }
}
TimeStampMarkerBlot.blotName = TIME_STAMP_MARKER;
export default TimeStampMarkerBlot;
TimeStampMarkerBlot.blotName = TIME_STAMP_MARKER;
// NOTE: Setting a custom tag name prevents some really weird bugs in quill.
TimeStampMarkerBlot.tagName = TIME_STAMP_MARKER;
