import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { escapeXMLString } from 'libs/xml-utils';
import { secondsToIso } from 'libs/duration';
dayjs.extend(duration);
export default class TrsxPhrase {
    constructor(begin, end, text, metadata) {
        this.b = begin;
        this.e = end;
        this.text = text;
        this.metadata = metadata;
    }
    serialize() {
        let content = this.text;
        const metadataString = this.serializeMetadata();
        if (this.metadata.speakerSign !== undefined) {
            const speakerSign = this.metadata.speakerSign.display === 'hide' ? '' : this.metadata.speakerSign.content;
            content = speakerSign + content;
        }
        return `        <p b="${this.b}" e="${this.e}"${metadataString}>${escapeXMLString(content)}</p>\n`;
    }
    serializeMetadata() {
        const entries = Object.entries(this.metadata);
        if (entries.length === 0)
            return '';
        let serializedMetadata = '';
        entries.forEach((entry) => {
            const [name, value] = entry;
            // minor conversion to conform with
            // https://gitlab.com/newtontech/beey/WebClient/-/wikis/TRSX-konvence#draft-metadata-pro-titulky
            if (name === 'captionEnd') {
                const isoValue = secondsToIso(value);
                serializedMetadata += ` caption="captionEnd" ${name}="${isoValue}"`;
            }
            else if (name === 'captionLineBreak') {
                // caption line break and caption end cannot be on the same phrase
                if (this.metadata.captionEnd === undefined) {
                    serializedMetadata += ' caption="lineBreak"';
                }
            }
            else if (name === 'timeAnchor') {
                // save timeAnchor value consistently with trsx
                const isoValue = secondsToIso(value);
                serializedMetadata += ` ${name}="${isoValue}"`;
            }
            else if (name === 'speakerSign') {
                const forceDisplay = value.display !== 'default' ? ' forceDisplay="true"' : '';
                serializedMetadata += ` caption="extraText"${forceDisplay}`;
            }
            else if (name === 'displayTimeStamp') {
                serializedMetadata += ' displayTimeStamp="true"';
            }
            else {
                serializedMetadata += ` ${name}="${escapeXMLString(String(value))}"`;
            }
        });
        return serializedMetadata;
    }
}
