import Quill from 'quill';
const Inline = Quill.import('blots/inline');
// The list represent the order of the way the blot will be displayed.
// Higher index, lower priority in displaying.
export const inlineblotOrder = [
    'cursor',
    'inline',
    'underline',
    'strike',
    'search',
    'keyword',
    'italic',
    'timeAnchor',
    'timeStampMarker',
    'uneditable',
    'warning',
    'bold',
    'script',
    'link',
    'code',
];
Inline.order = inlineblotOrder;
